@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Great+Vibes&family=IM+Fell+Great+Primer&family=Space+Grotesk:wght@300&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #AE67FA 1.84%, #F49867 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #040C18;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;

  --font-caption: 'Space Grotesk', sans-serif;
}

html {
  line-height: 1.5;
}

body {
  color: var(--foreground);
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  background-color: var(--background);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  font-family: "NHaasGroteskDSPro", "Lucida Sans", Tahoma, sans-serif;
  font-weight: 400;
  margin-bottom: 0.4em;
  text-transform: uppercase;
}

h1 {
  font-size: 45px;
  line-height: 0.95;
}

@media (min-width: 1024px) {
  h1 {
      font-size: 65px;
  }
}

h2 {
  font-size: 35px;
  line-height: 0.9;
  letter-spacing: 0.65px;
}

@media (min-width: 1024px) {
  h2 {
      font-size: 65px;
  }
}

h3 {
  font-size: 48px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 20px;
  margin-bottom: 25px;
  text-transform: none;
}

h6 {
  font-size: 15px;
  text-transform: none;
}

p {
  margin: 0 0 15px;
  font-size: 15px;
}