

@media screen and (max-width: 1080px){
  .lgt__footer-1{
    display: none;
  }
}

@media screen and (max-width: 700px) {
  
  .lgt__footer-3 li{
    display: none;
  }
  .lgt__footer-2{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}

@media screen and (max-width: 550px) {
  .lgt__footer-3 li{
    display: none;
  }
  .lgt__footer-2{
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
}