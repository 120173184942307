.lgt__navbar{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
}

.lgt__navbar-links{
  display: flex;
  justify-content: space-between;
  flex: 1;
  align-items: center;
  font-family: var(--font-family);
  
}

.lgt__navbar-links_logo{
  margin-right: 2rem;
  font-size: 25px;
  font-family: var(--text-handwriting);
}

.lgt__navbar-links_container{
  display: flex;
  flex-direction: row;
}

.lgt__navbar-links_container p{
  font-family: var(--font-family);
  color: white;
  font-size: 18px;
  font-weight: 500;
  line-height: 25px;
  text-transform: capitalize;
  margin: 0 4rem;
  cursor: pointer;
}


.lgt__navbar-links_container a{
  border: none;
  outline: none;
  text-decoration: none;
  color: black;
}

.lgt__navbar-menu{
  margin-left: 1rem;
  position: relative;
  display: none;
}


.lgt__navbar-menu_container{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  text-align: end;
  background: whitesmoke;
  padding: 2rem;
  position: absolute;
  top: 40px;
  right: 0;
  margin-top: 1rem;
  min-width: 180px;
  border-radius: 5px;
  box-shadow: 0 0 5 rgba(0,0,0,0.5);
}

.lgt__navbar-menu_container-links{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media screen and (max-width: 1080px){
  .lgt__navbar-links_container{
    display: none;
  }
  .lgt__navbar-menu{
    display: flex;
  }
}

@media screen and (max-width: 700px) {
  .lgt__navbar{
    padding: 2rem 4rem ;
  }
}

@media screen and (max-width: 550px) {
  .lgt__navbar{
    padding: 2rem;
  }

  .lgt__navbar-menu_container{
    top: 20px;
  }
  
}

.header-0-3-3 {
  top: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 1002;
  position: fixed;
  background-color: var(--background);
  }

.list-0-3-6 {
  margin: 0 -7.5px;
  display: flex;
  padding: 0;
  list-style: none;
  justify-content: space-between;
  }

a {
  color: currentColor;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding: 0;
  overflow: hidden;
  position: relative;
  font-size: 10px;
  background: transparent;
  min-height: 42px;
  align-items: center;
  font-family: "NHaasGroteskTXPro", "Lucida Sans", Tahoma, sans-serif;
  line-height: 1.2;
  border-radius: 0;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-decoration: none;
  }



