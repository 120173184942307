
.lgt__header{
  text-align: center;
}

.lgt__header-img{
  width: 500px;
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  
}

.image{
  display: flex;
}

.lgt__header_profile{
  border-radius: 20px;
}

h1{
  font-size: 8.5vw;
  max-width: 100%;
  grid-column: 1 / span 7;
  opacity: 75%;
}

.img-caption{
  font: var(--font-caption);
}